import { combineReducers } from '@reduxjs/toolkit';
import airaSlice from './airaSlice';
import authSlice from './authSlice';
import clientSlice from './clientSlice';
import dashboardSlice from './dashboardSlice';
import dropdownSlice from './dropdownSlice';
import propertySlice from './propertySlice';

const reducers = combineReducers({
  aira: airaSlice.reducer,
  auth: authSlice.reducer,
  client: clientSlice.reducer,
  dashboard: dashboardSlice.reducer,
  dropdown: dropdownSlice.reducer,
  property: propertySlice.reducer
});

export default reducers;
