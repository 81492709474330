import { createSlice } from '@reduxjs/toolkit';
import { getInsights } from 'api/dashboard';

export const clientFilterInitialValues = {
  branch: '',
  createdFromDate: '',
  createdToDate: ''
};

const dashboardSlice = createSlice({
  initialState: {
    filters: clientFilterInitialValues,
    isLoading: false
  },
  name: 'dashboard',
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const dashboardActions = dashboardSlice.actions;

export const handleGetInsights = (filters) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getInsights(filters);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export default dashboardSlice;
