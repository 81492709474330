import { axiosClient } from '../utils/axiosClient';

export const getInsights = (filters = {}) => {
  const { createdFromDate, createdToDate, branch } = filters;

  const params = {
    branch: branch,
    from_date: createdFromDate,
    to_date: createdToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get('v1/realtors/insights' + queryString).then((resp) => resp?.data);
};
