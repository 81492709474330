const ImagesLinks = {
  internetProblem: require('assets/images/internet-problem.png'),
  loginBackground: require('assets/images/login-bg.png'),
  notFound: require('assets/images/NotFound.png'),
  propertyImage: require('assets/images/PropertyImg.png'),
  rehubLogo: require('assets/images/rehub-logo.png'),
  sidebar: {
    airaIcon: require('assets/images/aira-icon.png')
  }
};

export default ImagesLinks;
