import { axiosClient } from '../utils/axiosClient';

const propertiesEndpoint = 'v1/clients/properties';

export const getProperties = (filters = {}) => {
  const { page = 0, limit = 10, createdFromDate, createdToDate, propertyStatus } = filters;

  const params = {
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    property_status: propertyStatus,
    to_date: createdToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(propertiesEndpoint + queryString).then((resp) => resp?.data);
};

export const getPropertyDetails = (clientId, propertyId) => {
  return axiosClient
    .get(`v1/client/${clientId}/property/${propertyId}/details`)
    .then((resp) => resp?.data);
};

export const getPropertyLeads = (clientId, propertyId) => {
  return axiosClient
    .get(`v1/client/${clientId}/property/${propertyId}/leads`)
    .then((resp) => resp?.data);
};

export const verifyProperty = (clientId, propertyId, params) => {
  return axiosClient
    .patch(`v1/agent/${clientId}/property/${propertyId}/verify`, params)
    .then((resp) => resp?.data);
};

export const getPropertyVerificationDropdown = () => {
  return axiosClient
    .get(`v1/agent/property/verification/dropdown/values`)
    .then((resp) => resp?.data);
};

export const getDuplicateProperties = (propertyId, limit, offset) => {
  return axiosClient
    .get(
      `v1/property/duplicates?limit=${limit}&offset=${offset}&property_id=${propertyId}&radius=15`
    )
    .then((resp) => resp?.data);
};
